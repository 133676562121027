import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { withNamespaces } from "react-i18next";

class Footer extends React.Component {

    render() {
        const {t, i18n} = this.props;

        return (
            <footer id="dark-footer" className="footer footer-dark">
                {/* Dark footer */} 
                <div className="container">
                    <div className="columns">
                        <div className="column">
                            <div className="footer-column">
                                <div className="footer-header">
                                    <h3>{t("atl.title")}</h3>
                                </div>
                                <ul className="link-list">
                                    <li><a href="/missions">{t("atl.liste")}</a></li>
                                    {/* 
                                    <li><a href="/univers">{t("atl.univers")}</a></li>
                                    <li><a href="/manual">{t("atl.manuel")}</a></li>
                                    */}
                                    <li><a href="/FAQ">{t("atl.FAQ")}</a></li>
                                    <li><a target="_blank" href="https://blog.atlantide.io">{t("atl.actualite")}</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="column">
                            <div className="footer-column">
                                <div className="footer-header">
                                    <h3>{t("agence.title")}</h3>
                                </div>
                                <ul className="link-list">
                                    <li><a href={"/legal/"+i18n.language+"/legal.html"}>{t("agence.legals")}</a></li>
                                    <li><a href={"/legal/"+i18n.language+"/privacy.html"}>{t("agence.privacy")}</a></li>
                                    <li><a href={"/legal/"+i18n.language+"/rgpd.html"}>{t("agence.rgpd")}</a></li>
                                    <li><a href={"/legal/"+i18n.language+"/cgv.html"}>{t("agence.cgv")}</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="column">
                            <div className="footer-column hidden">
                                <div className="footer-header">
                                    <h3>Learning</h3>
                                </div>
                                <ul className="link-list">
                                    <li><a href="#">Resources</a></li>
                                    <li><a href="#">Blog</a></li>
                                    <li><a href="#">FAQ</a></li>
                                    <li><a href="#">API documentation</a></li>
                                    <li><a href="#">Admin guide</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="column">
                            <div className="footer-column">
                                <div className="footer-logo">
                                    <img src={require("../images/logos/atlantide_logo_line.png")} alt=""/>
                                </div>
                                <div className="footer-header">
                                    <nav className="level is-mobile">
                                        <div className="level-left level-social">
                                            <a href="https://www.facebook.com/atlantide.io/" target="_blank" className="level-item">
                                                <span className="icon"><i className="fa fa-facebook"></i></span>
                                            </a>
                                            <a href="https://www.instagram.com/atlantide_io/" target="_blank" className="level-item">
                                                <span className="icon"><i className="fa fa-instagram"></i></span>
                                            </a>
                                            <a href="/contact" className="level-item">
                                                <span className="icon"><i className="fa fa-at"></i></span>
                                            </a>
                                        </div>
                                    </nav>
                                </div>
                                <div className="copyright">
                                    <span className="moto light-text">{t("love.p1")} <i className="icon-love fa fa-heart color-red"></i><br/>
                                    {t("love.p2")} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* /Dark footer */}
            </footer>
        )
    }
}

export default withNamespaces("Footer")(Footer)
