/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, image, schemaOrg, forceFullPathImage}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author,
            siteUrl,
            image
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  let metaImage = image || site.siteMetadata.image;
  let metaSchema = schemaOrg || {
                                  '@context': 'https://schema.org',
                                  '@type': 'Organization',
                                  url: 'http://www.atlantide.io',
                                  name: 'Atlantide IO',
                                };

  let fullUrlImage;
  if(forceFullPathImage) 
    fullUrlImage = metaImage;
  else
    fullUrlImage = site.siteMetadata.siteUrl+"/"+metaImage;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`${site.siteMetadata.title} | %s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: `${site.siteMetadata.title} | ${title}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: site.siteMetadata.siteUrl,
        },
        {
          property: `og:image`,
          content: fullUrlImage
        },
        {
          property: `og:image:width`,
          content: 600,
        },
        {
          property: `og:image:height`,
          content: 314,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      {
        (metaSchema)?
          <script type="application/ld+json">
            {JSON.stringify(metaSchema)}
          </script>
        :null
      }
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
