import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

class Loader extends React.Component {
  
  state = {
    loaderActive: true
  }

  componentDidMount() {
    this.check();
  }

  check() {
    setTimeout(() => {
      if(!this.props.force) {
        this.setState({
          loaderActive: false
        })
      } else {
        this.check();
      }
    }, 1000)
  }

  render() {

    const loadingClass = (this.state.loaderActive)?"is-active":"";
    const loadingInfra = (this.state.loaderActive)?"":"";

    return (
      <span>
          <div className={"pageloader "+loadingClass}></div>
          <div className={"infraloader "+loadingInfra}></div>
      </span>
    )
  }
}

export default Loader
