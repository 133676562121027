import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { withNamespaces } from "react-i18next";
import LanguageSwitcher from './languageSwitcher';

class NavBar extends React.Component {

  state = {
    transparent : true,
    menuShow: false,
    showBlog: true,
    annonceClass : "annonce"
  }

  componentDidMount() {
    window.addEventListener('scroll', () => {this.handleScroll()});
    this.updateBlog();

    setTimeout(() => {
      this.setState({
        annonceClass : "annonce visible"
      });
    }, 2000);
  }


  hiddeAnonce() {
      this.setState({
        annonceClass : "annonce"
      });
  }


  componentWillUnmount() {
      window.removeEventListener('scroll', () => {this.handleScroll()});
  }

  componentDidUpdate() {
      this.updateBlog();
  }

  updateBlog() {
    const { i18n } = this.props;
    
    if(i18n.language === "en" && this.state.showBlog) {
        this.setState({
            showBlog:false
        })
    } else if(i18n.language === "fr" && ! this.state.showBlog){
        this.setState({
            showBlog:true
        })
    } 
  }

  getSideBar() {
    return (
        <div className="side-navigation-menu">
            {/*Categories menu*/}
            <div className="category-menu-wrapper">
                {/*Menu*/}
                <ul className="categories">
                    <li className="square-logo"><img src={require("../images/logos/square-white.svg")} alt=""/></li>
                    <li className="category-link is-active" data-navigation-menu="demo-pages"><i className="sl sl-icon-layers"></i></li>
                    <li className="category-link" data-navigation-menu="onepagers"><i className="sl sl-icon-energy"></i></li>
                    <li className="category-link" data-navigation-menu="components"><i className="sl sl-icon-grid"></i></li>
                </ul>
                {/*Menu*/}

                <ul className="author">
                    <li>
                        {/*Theme author*/}
                        <a href="https://cssninja.io" target="_blank">
                            <img className="main-menu-author" src={require("../images/logos/cssninja.svg")} alt="" />
                        </a>
                    </li>
                </ul>
            </div>
            {/*/Categories menu*/}

            {/* Navigation menu */}
            <div id="demo-pages" className="navigation-menu-wrapper animated preFadeInRight fadeInRight">
                {/* Navigation Header */}
                <div className="navigation-menu-header">
                    <a className="ml-auto hamburger-btn navigation-close" href="#!">
                        <span className="menu-toggle">
                            <span className="icon-box-toggle">
                                <span className="rotate">
                                    <i className="icon-line-top"></i>
                                    <i className="icon-line-center"></i>
                                    <i className="icon-line-bottom"></i>
                                </span>
                            </span>
                        </span>
                    </a>
                </div>
                {/* Navigation Body */}
                <ul className="navigation-menu">
                    <li className="has-children"><a className="parent-link" href="#"><span className="material-icons">weekend</span>Agency kit</a>
                        <ul>
                            <li><a className="is-submenu" href="/agency/index.html">Homepage</a></li>
                            <li><a className="is-submenu" href="/agency/agency-about.html">About</a></li>
                            <li><a className="is-submenu" href="/agency/agency-portfolio.html">Portfolio</a></li>
                            <li><a className="is-submenu" href="/agency/agency-contact.html">Contact</a></li>
                            <li><a className="is-submenu" href="/agency/agency-blog.html">Blog</a></li>
                            <li><a className="is-submenu" href="/agency/agency-post-sidebar.html">Post sidebar</a></li>
                            <li><a className="is-submenu" href="/agency/agency-post-nosidebar.html">Post no sidebar</a></li>
                        </ul>
                    </li>
                    <li className="has-children"><a className="parent-link" href="#"><span className="material-icons">wb_incandescent</span>Startup
                            kit</a>
                        <ul>
                            <li><a className="is-submenu" href="/startup/index.html">Homepage</a></li>
                            <li><a className="is-submenu" href="/startup/startup-about.html">About</a></li>
                            <li><a className="is-submenu" href="/startup/startup-product.html">Product</a></li>
                            <li><a className="is-submenu" href="/startup/startup-contact.html">Contact</a></li>
                            <li><a className="is-submenu" href="/startup/startup-login.html">Login</a></li>
                            <li><a className="is-submenu" href="/startup/startup-signup.html">Sign up</a></li>
                        </ul>
                    </li>
                    <li className="has-children"><a className="parent-link" href="#"><span className="material-icons">looks</span>Landing kit
                            v1</a>
                        <ul>
                            <li><a className="is-submenu" href="/landing-kits/kit-1/index.html">Landing page</a></li>
                            <li><a className="is-submenu" href="/landing-kits/kit-1/landing-features.html">Feature page</a></li>
                            <li><a className="is-submenu" href="/landing-kits/kit-1/landing-pricing.html">Pricing page</a></li>
                            <li><a className="is-submenu" href="/landing-kits/kit-1/landing-login.html">Login page</a></li>
                            <li><a className="is-submenu" href="/landing-kits/kit-1/landing-signup.html">Signup page</a></li>
                        </ul>
                    </li>
                    <li className="has-children"><a className="parent-link" href="#"><span className="material-icons">invert_colors</span>Landing
                            kit v2</a>
                        <ul>
                            <li><a className="is-submenu" href="/landing-kits/kit-2/index.html">Landing page</a></li>
                            <li><a className="is-submenu" href="/landing-kits/kit-2/landing-v1-features.html">Feature page</a></li>
                            <li><a className="is-submenu" href="/landing-kits/kit-2/landing-v1-pricing.html">Pricing page</a></li>
                            <li><a className="is-submenu" href="/landing-kits/kit-2/landing-v1-login.html">Login page</a></li>
                            <li><a className="is-submenu" href="/landing-kits/kit-2/landing-v1-signup.html">Signup page</a></li>
                        </ul>
                    </li>
                    <li className="has-children"><a className="parent-link" href="#"><span className="material-icons">chat_bubble</span>Landing
                            kit v3</a>
                        <ul>
                            <li><a className="is-submenu" href="/landing-kits/kit-3/index.html">Landing page</a></li>
                            <li><a className="is-submenu" href="/landing-kits/kit-3/landing-v2-features.html">Feature page</a></li>
                            <li><a className="is-submenu" href="/landing-kits/kit-3/landing-v2-pricing.html">Pricing page</a></li>
                            <li><a className="is-submenu" href="/landing-kits/kit-3/landing-v2-login.html">Login page</a></li>
                            <li><a className="is-submenu" href="/landing-kits/kit-3/landing-v2-signup.html">Signup page</a></li>
                        </ul>
                    </li>
                    <li className="has-children"><a className="parent-link" href="#"><span className="material-icons">business</span>Landing
                            kit v4</a>
                        <ul>
                            <li><a className="is-submenu" href="/landing-kits/kit-4/index.html">Landing page</a></li>
                            <li><a className="is-submenu" href="/landing-kits/kit-4/landing-v3-pricing.html">Pricing page</a></li>
                            <li><a className="is-submenu" href="/landing-kits/kit-4/landing-v3-help.html">Help center</a></li>
                            <li><a className="is-submenu" href="/landing-kits/kit-4/landing-v3-help-category.html">Help category</a></li>
                            <li><a className="is-submenu" href="/landing-kits/kit-4/landing-v3-help-article.html">Help article</a></li>
                            <li><a className="is-submenu" href="/landing-kits/kit-4/landing-v3-signup.html">Login</a></li>
                            <li><a className="is-submenu" href="/landing-kits/kit-4/landing-v3-login.html">Sign up</a></li>
                        </ul>
                    </li>
                    <li className="has-children"><a className="parent-link" href="#"><span className="material-icons">face</span>Landing kit v5</a>
                        <ul>
                            <li><a className="is-submenu has-new-feature" href="/landing-kits/kit-5/index.html">Landing page</a></li>
                            <li><a className="is-submenu has-new-feature" href="/landing-kits/kit-5/landing-v4-features.html">Feature
                                    page</a></li>
                            <li><a className="is-submenu has-new-feature" href="/landing-kits/kit-5/landing-v4-pricing.html">Pricing
                                    Page</a></li>
                            <li><a className="is-submenu has-new-feature" href="/landing-kits/kit-5/landing-v4-login.html">Login /
                                    Signup</a></li>
                        </ul>
                    </li>
                    <li className="has-children"><a className="parent-link" href="#"><span className="material-icons">cloud</span>Landing kit
                            v6</a>
                        <ul>
                            <li><a className="is-submenu has-new-update" href="/landing-kits/kit-6/index.html">Landing page</a></li>
                            <li><a className="is-submenu has-new-update" href="/landing-kits/kit-6/landing-v5-home.html">Landing page
                                    v2</a></li>
                            <li><a className="is-submenu has-new-update" href="/landing-kits/kit-6/landing-v5-login.html">Login page</a></li>
                            <li><a className="is-submenu has-new-update" href="/landing-kits/kit-6/landing-v5-signup.html">Signup page</a></li>
                        </ul>
                    </li>
                    <li className="has-children"><a className="parent-link has-new" href="#"><span className="material-icons">backspace</span>Error
                            Kit</a>
                        <ul>
                            <li><a className="is-submenu has-new-update is-2_4" href="/landing-kits/kit-error/index.html">Error 404 (V1)</a>
                            </li>
                            <li><a className="is-submenu has-new-update is-2_4" href="/landing-kits/kit-error/error-2.html">Error 404 (V2)</a>
                            </li>
                            <li><a className="is-submenu has-new-update is-2_4" href="/landing-kits/kit-error/error-3.html">Error 404 (V3)</a>
                            </li>
                            <li><a className="is-submenu has-new-update is-2_4" href="/landing-kits/kit-error/error-4.html">Error 404 (V4)</a>
                            </li>
                            <li><a className="is-submenu has-new-update is-2_4" href="/landing-kits/kit-error/error-5.html">Error 404 (V5)</a>
                            </li>
                            <li><a className="is-submenu has-new-update is-2_4" href="/landing-kits/kit-error/error-6.html">Error 404 (V6)</a>
                            </li>
                            <li><a className="is-submenu has-new-update is-2_4" href="/landing-kits/kit-error/error-7.html">Error 500 (V1)</a>
                            </li>
                            <li><a className="is-submenu has-new-update is-2_4" href="/landing-kits/kit-error/error-8.html">Error 500 (V2)</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            {/* /Navigation menu */}

        </div>
    )
  }

  handleScroll() {
    const scrollY = window.scrollY;
    const treshold = 200;//window.innerHeight - 200

    if(scrollY > treshold && this.state.transparent) {
      this.setState({
        transparent: false
      })

    } else if(scrollY < treshold && !this.state.transparent) {
      this.setState({
        transparent: true
      })
    }
  }

  render() {
    const { siteTitle, t, i18n } = this.props;
  
    let transparentClass = "";
    const ln = i18n.language.split("-")[0];
    
    if(this.state.transparent && !this.props.foceSolide) {
        transparentClass = "is-transparent navbar-fade navbar-light"
    } else {
        if(this.state.menuShow)
            transparentClass = "is-transparent navbar-fade navbar-light"
        else
            transparentClass = "is-solid navbar-faded"
    }
        

    return (
      <div>
        <div className="navbar-placeholder" style={{height: "64px"}}>
          <nav className={"navbar navbar-wrapper  "+transparentClass} style={{flexDirection:"column", height:'auto'}}>
            <div className={this.state.annonceClass}>
                <img src={require("../images/illustrations/icons/annonce.png")}/> 
                {t("studio")}

                <a href="https://studio.atlantide.io" target="_blank" className="button button-cta btn-align raised primary-btn">
                    {t("knowMore")}
                </a>

                <div className="close" onClick={() => {this.hiddeAnonce()}}>X</div>
            </div>

            <div className="container">
                <div className="navbar-brand">

                    <div className="language">
                        <LanguageSwitcher/>
                    </div>


                    <a className="navbar-item" id='logo-main' href="/">
                        <img className="light-logo hidden" src={require("../images/logos/atlantide_logo.svg")} alt=""/>
                        <img className="dark-logo" src={require("../images/logos/atlantide_logo_c.svg")} alt=""/>
                    </a>


                    <div className="custom-burger" data-target="">
                        <a id="" className="responsive-btn" onClick={() => { this.setState({menuShow: ! this.state.menuShow} )}}>
                            <span className="menu-toggle">	
                                <span className={(this.state.menuShow)?"icon-box-toggle active":"icon-box-toggle"}> 	
                                    <span className="rotate">
                                        <i className="icon-line-top"></i>
                                        <i className="icon-line-center"></i>
                                        <i className="icon-line-bottom"></i> 
                                    </span>
                                </span>
                            </span>
                        </a>
                    </div>
                </div>

                <div className={(this.state.menuShow)?"navbar-menu is-active":"navbar-menu"}>
                    <div className="navbar-start">
                        <a className="navbar-item is-slide" href="/">
                            {t("accueil")}
                        </a>

                        <a className="navbar-item is-slide hidden" href="/univers">
                            {t("univers")}
                        </a>

                        <a className="navbar-item is-slide" href="/app/missions">
                            {t("missions")}
                        </a>

                        <a className="navbar-item is-slide hidden" href="/partners">
                            {t("partners")}
                        </a>

                        <a className="navbar-item is-slide" href="/faq">
                            {t("faq")}
                        </a>

                        {
                            (this.state.showBlog)?
                                <a className="navbar-item is-slide"  target="_blank" href="https://blog.atlantide.io">
                                    {t("actualite")}
                                </a>
                            :null
                        }

                        <a className="navbar-item is-slide" href="/contact" id="contact-item">
                            {t("contact")}
                        </a>
                    </div>

                    <div className="navbar-end">

                        <span id="store-container">
                            <div className="navbar-item">
                                <a id="app-store" href="https://apps.apple.com/fr/app/atlantide-io/id1364973121" target="_blank" className="raised store">
                                    <img src={require("../images/illustrations/icons/app_store_"+ln+".png")} />
                                </a>
                            </div>
                            <div className="navbar-item">
                                <a id="playstore" href="https://play.google.com/store/apps/details?id=com.magnitudelabs.atlantide" target="_blank" className="raised store">
                                    <img src={require("../images/illustrations/icons/play_store_"+ln+".png")} />
                                </a>
                            </div>
                        </span>

                        <div className="navbar-item">
                            <a id="#signup-btn" href="#!" className="hidden button button-signup btn-outlined is-bold btn-align light-btn  raised">
                                Rejoindre
                            </a>
                        </div>
                    </div>
                </div>
            </div>
          </nav>
        </div>
          {
            /*this.getSideBar()*/
          }
      </div>
    )
  }
}

export default withNamespaces("NavBar")(NavBar);
