import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import fr from "../images/illustrations/icons/countries/fr.png";
import en from "../images/illustrations/icons/countries/us.png";

const flags = {
  fr,
  en
}

class LanguageSwitcher extends Component {

    state = {
        lnId : 0
    }

    languages = [
        { code: "en", label: "English" },
        { code: "fr", label: "Français" },
    ];

  constructor(props) {
    super(props);
    this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
  }

  componentDidMount() {
    const { i18n } = this.props;
    
    if(i18n.language !== "en" && i18n.language !== "fr")
      i18n.changeLanguage("en");

    this.updateFlags(i18n.language);
  }

  updateFlags(ln) {
    let id = 0;
    for(let i = 0 ; i < this.languages.length ; i++) {
        if(this.languages[i].code === ln) {
            id = i;
        }
    }
    this.setState({ language: ln, lnId : id });
  }

  componentWillReceiveProps(nextProps) {
    const ln = nextProps.i18n.language;
    this.updateFlags(ln);
  }

  handleChangeLanguage() {
    const { i18n } = this.props;

    let lnId = this.state.lnId + 1;
    if(lnId >= this.languages.length)
        lnId = 0;
    
    const language = this.languages[lnId].code;

    this.setState({
        language,
        lnId
    })

    i18n.changeLanguage(language);
  }

  renderLanguageChoice({ code }) {
    let buttonClass = "flag";

    if(this.state.language === code) {
      buttonClass += " selected";
    } else
        return null;

    return (
      <img
        src={flags[code]}
        key={code}
        className={buttonClass}
        alt={code}
        onClick={() => { 
          this.handleChangeLanguage();
          if(this.props.callback)
            this.props.callback(); 
        }}
      />
    );
  }

  render() {

    return (
      <div className="languageSwitcher">
        { this.languages.map((ln) => this.renderLanguageChoice(ln)) }
      </div>
    );
  }
}

export default withNamespaces()(LanguageSwitcher);