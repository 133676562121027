/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./navbar"
import i18n from '../i18n'
import '../assets/sass/bulma.sass'
import '../assets/scss/core.scss'
import '../assets/css/icons.min.css'
import withLocation from "./withLocation"
import { withNamespaces } from "react-i18next";

/* OLD CONF BEFORE 
const playersMonthsCoef = [
    0.3,0.3,0.3,0.6,1,2,2,2,2,1.3,1.4,1,0.5
]
const playersDaysBase = [
    1.2,0.5,0.5,1,0.5,0.6,1.2
]
const playersHoursBase = [
    0,0,0,0,0,0,0,0,0,0,1,1.3,2,2,2,1.3,1.3,1.3,1,1,1,0,0,0
]
*/

const playersMonthsCoef = [
    0.1,0.1,0.1,0.3,0.5,1,1,1,1,0.6,0.7,0.5,0.25
]
const playersDaysBase = [
    0.6,0.2,0.25,0.5,0.25,0.3,0.6
]
const playersHoursBase = [
    0,0,0,0,0,0,0,0,0,0,0.5,0.5,1,1,1,0.5,0.5,0.6,0.5,0.5,0.5,0,0,0
]

const getLivePlayers = () => {
    let nbPlayers = 0;
    const nbPlayerCoef = 40;
    
    const now = new Date();

    const hoursIndex = now.getHours();
    const dayIndex = now.getDay();
    const monthIndex = now.getMonth();

    nbPlayers = nbPlayerCoef * playersHoursBase[hoursIndex] * playersDaysBase[dayIndex] * playersMonthsCoef[monthIndex]
    nbPlayers = Math.round(nbPlayers);

    return nbPlayers;
}

class Layout extends React.Component {

  render () {
    const { children, t } = this.props;
    const nbPlayer = getLivePlayers();
    
    return (
      <>

        <main>{children}</main>
        <div className="nbAgents">
            <a href="/app/missions/"><div className="dot blue has-ripple"></div> { nbPlayer } {(nbPlayer > 1)?t("currently"):t("currently-single")}</a>
        </div>

        <div className="nbAgents hidden" style={{width:"100vw", textAlign:"center", fontSize:"1.5em"}}>
            <a href="https://archives-game.atlantide.io" target="_blank">
              {t("archives")}

              <i className="im im-icon-Hand-Touch" style={{marginLeft:10}}></i>
            </a>
        </div>
      </>
    )
  }
}

export default withNamespaces()(Layout);
